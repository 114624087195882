<template>
	<div id="links_list">
		<div class="container">
			<div class="col_left">
				<Left></Left>
			</div>
			<div class="col_right">
				<div class="title">
					<h3>友情链接设置</h3>
					<p>当前位置：<router-link to="/user_center/zc_message">会员中心</router-link> / 主页设置 / 友情链接设置</p>
				</div>
				<div class="active_header">
					<div class="header_a" @click="editLinks">添加链接</div>
					<!-- <div class="search_box">
						<form>
							<input type="text" name="keywords" id="keywords" value="">
							<button type="submit" onclick="sousuo()">搜索</button>
						</form>
					</div> -->
				</div>
				<el-table :data="linkList.list" style="width: 100%">
					<el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
					<el-table-column align="center" prop="name" label="标题" width="220"></el-table-column>
					<el-table-column align="center" prop="link" label="网址" width="290"></el-table-column>
					<el-table-column align="center" prop="city" label="创建时间" width="130"></el-table-column>
					<el-table-column align="center" label="操作" width="218">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
							<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "../company/Left.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			Left
		},
		data() {
			return {
				company: {},
				linkList:{
					company_id:'',
					list:[],
					limit:10,
					page:1
				}
			}
		},
		mounted() {
			this.get_detail();
		},
		computed: {
			...mapGetters(['user'])
		},
		methods: {
			handleEdit(index, row) {
				this.$router.push({
					path: '/edit/edit_links',
					query: {
						row: row
					}
				})
			},
			handleDelete(index, row) {
				this.$http_qm.post('company_link/delete',{id:row.id}).then(res => {
					if(res.code == 200){
						this.linkList.list.splice(index,1);
					}
				}).catch(err => {
					console.log(err)
				})
			},
			get_detail() {
				this.$http_qm.post('company/detail', {
					type: 'me'
				}).then(res => {
					if (res.code == 200) {
						this.company = res.data;
						this.linkList.company_id = this.company.id;
						this.link_list();
					}
				}).catch(err => {
					console.log(err);
				})
			},
			link_list(){
				let data = this.linkList;
				this.$http_qm.post('company_link/list', data).then(res => {
					if (res.code == 200) {
						this.linkList.list = res.data;
					}
				}).catch(err => {
					console.log(err);
				})
			},
			editLinks(){
				this.$router.push({
					path: '/edit/edit_links',
					query: {
						id: this.company.id
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#links_list {
		background: #f7f7f7 !important;

		.container {
			width: 1200px;
			margin: auto;
			padding: 20px 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.col_left {
				width: 230px;
				border: 1px solid #e6e6e6;
				background: #fff;
				border-top: none;
			}

			.col_right {
				width: 940px;
				border: 1px solid #e6e6e6;
				background: #fff;
				box-sizing: border-box;

				.title {
					height: 60px;
					padding: 0 20px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					h3 {
						line-height: 60px;
						font-size: 16px;
						color: #333;
					}

					p {
						line-height: 60px;
						font-size: 12px;
						color: #999;
					}
				}

				.active_header {
					height: 36px;
					padding: 20px;
					border-top: 1px solid #e6e6e6;
					border-bottom: 1px solid #e6e6e6;
					font-size: 14px;

					.header_a {
						float: left;
						width: 120px;
						height: 36px;
						line-height: 36px;
						color: #fff;
						text-align: center;
						background: #0088ff;
					}

					.search_box {
						float: right;
						width: 700px;
						height: 36px;
						line-height: 36px;

						input {
							width: 589px;
							height: 34px;
							line-height: 34px;
							padding: 0 15px;
							border: 1px solid #ccc;
							border-right: 0;
						}

						button {
							float: right;
							width: 80px;
							height: 36px;
							color: #fff;
							text-align: center;
							background: #0088ff;
							cursor: pointer;
							border: 0;
						}
					}
				}

				.el-table__header {
					tr {
						background: #f7f7f7;
					}
				}

				.el-table {

					td {
						border: none;
					}
				}
			}
		}
	}
</style>
